import React from 'react';
import botaina from './assets/images/botaina1.gif';
import './webchat/MinimizableWebChat.scss';
import classNames from 'classnames';

export const LoadingAR = () => {

return <>
<div className='minimizable-web-chat'>
    <div
      className={classNames(
        'chat-box ',
        'dark',
      )}>
        <div className={`info-steps dark`}>     
            <img loading='lazy' src={botaina} alt='Logo Qatar Foundation' />
            <div className="info-steps__text">
                <p>{'مرحبًا، '}</p>
                <p>{'أنا بوتينة'}</p>
            </div>
            
            <div className='info-steps__bottom'>
                <h2 style={{ color: '#fff' }}>{ 'تحميل...' }</h2>
            </div>
        </div>
    </div>
</div>
<div className={`web-chat-background active`}></div>
</>
};
