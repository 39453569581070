import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Loading } from './Loading';
import { LoadingAR } from './LoadingAR';
import './assets/fonts/din-next-lt-pro-arabic/DINNextLTPro-Regular.woff2'
import './index.css';

const MinimizableWebChat = React.lazy(() => import('./webchat/MinimizableWebChat'))

const App = () => {
    return (
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Suspense fallback={<Loading />}><MinimizableWebChat isHomePage={true} lang={'en'} /></Suspense>} />
            <Route exact path="/ar" element={<Suspense fallback={<LoadingAR />}><MinimizableWebChat isHomePage={true} lang={'ar'} /></Suspense>} />
          </Routes>
        </div>
      </Router>
    );
  }
  
  export default App;
